//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import EditOwnerProfile from '@/modules/owner-profile/components/edit-owner-profile.vue';

export default {
  name: 'app-owner-profile',
  components: {
    [EditOwnerProfile.name]: EditOwnerProfile,
  },

  data() {
    return {
      searchInput: '',
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    // hasPermissionToCreate() {
    //   return new TripPermissions(this.currentUser).create;
    // },
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
};
