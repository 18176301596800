//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import i18n from '@/vueI18n'
import { UserModel } from '@/modules/auth/user-model';
const { fields } = UserModel;
import { FormSchema } from '@/shared/form/form-schema';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import firebase from 'firebase/app';
import 'firebase/storage';

const formSchema = new FormSchema([
  fields.avatarsProfile,
  fields.avatars,
  fields.fullName,
  fields.firstName,
  fields.lastName,
  fields.workingEmail,
  fields.alternativePhoneNumber,
  fields.countryCode,
  fields.companyId
]);



export default {
  name: 'app-edit-owner-profile',

  data() {
    return {
        firstName: '',
        lastName: '',
        code:'',
        countryCodes: [

        ],
        phoneNumber: '',
        avatar: '',
        alternativePhoneNumber: '',
        TRansObject: {
          countrySelectorLabel: 'Code Country',
          countrySelectorError: 'Choisir un pays',
          phoneNumberLabel: 'Phone Number',
          example: 'Example :',
        },
        TRansObjectAr: {
          countrySelectorLabel: 'كود البلد',
          countrySelectorError: 'Choisir un pays',
          phoneNumberLabel: 'رقم الجوال',
          example: 'مثال :',
        },
        CCode:'',
        isCCode:'',  
        loadingImages: false, 
    }
  },
  components:{
    VuePhoneNumberInput,
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
      loadingUpdateProfile: 'auth/loadingUpdateProfile',
    }),
    fields() {
      return fields;
    },
    isRTL(){
      return i18n.locale == 'ar'
    }
    // hasPermissionToCreate() {
    //   return new TripPermissions(this.currentUser).create;
    // },
  },
  methods: {
    ...mapActions({
      doUpdateProfile: 'auth/doUpdateProfile',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    async doSubmit() {
      console.log(this.model);
      debugger
      if(!this.avatar){
        await this.doUpdateProfile(this.model)
      }
      else{
        console.log(this.avatar);
        debugger
        await this.uploadAvatar(`saryahCompany/user/${this.currentUser.id}/avatar`, this.avatar)
      }
    },
     async uploadAvatar(path, request){
            this.loadingImages = true
            console.log(request);            
            const ref = firebase.storage().ref();
            const fullPath = `${path}/${request.name}`;
            console.log('full path',fullPath)            
            const task = ref.child(fullPath).put(request);

            await task.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) => {
                const percent =
                (snapshot.bytesTransferred /
                    snapshot.totalBytes) *
                100;
                console.log('precent', percent);
            },
            (error) => {
                // request.onError(error);
                console.log(error);
            },
            () => {
                task.snapshot.ref
                .getDownloadURL()
                .then(async (url) => {
                    console.log(url);
                    this.model.avatars[0].publicUrl = url
                    this.model.avatars[0].name = request.name
                    this.doUpdateProfile(this.model)
                    this.loadingImages = false
                    // this.doNext()
                })
                .catch((error) => {
                    console.log('error', error);
                    // request.onError(error);
                });
            },
            );
        },
    onUpdate(data) {
      this.model.alternativePhoneNumber = data.formatInternational
    },
    getProfileImg(value){
      this.avatar = value
    },
  },

  created(){
        console.log(this.currentUser);
        this.model = formSchema.initialValues(this.currentUser);
        this.model.email = this.currentUser.email
        this.model.avatars = []
        this.model.phoneNumber = this.currentUser.phoneNumber
        this.model.accountType = this.currentUser.accountType
        this.model.avatars[0] = {
          publicUrl: this.currentUser.avatars && this.currentUser.avatars.length > 0 ? this.currentUser.avatars[0].publicUrl : '',
          name: this.currentUser.avatars && this.currentUser.avatars.length > 0 ?  this.currentUser.avatars[0].name :  '',
        }
        this.alternativePhoneNumber = this.currentUser.alternativePhoneNumber        
    }
};
